import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showToast } from "@/helpers/toasts"
import { cloneDeep } from "lodash-es"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, shopId) => {
    try {
      const { data } = await api.get(`${baseURI}/${shopId}/access_settings`)
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  UPDATE_ITEM: async ({ commit }, { shopId, ...params }) => {
    try {
      const { data } = await api.post(`${baseURI}/${shopId}/access_settings`, params)
      commit("SET_ITEM", data.data)
      commit("SET_INITIAL_ITEM", cloneDeep(data.data))
      showToast({
        title: i18n.t("success"),
        text: i18n.t("company_system.shop_settings.access_setting_updated"),
        variant: "success"
      })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
