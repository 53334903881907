<template lang="pug">
  .shop-form-container
    ArrowBack(@back="handleClickBack")
    AppOverlayLoader(:state="loading")
    BTabs(
      fill
      vertical
      card
      nav-wrapper-class="nav-wrapper"
      @activate-tab="handleTabChange"
    )
      BTab(
        v-for="tab in tabs"
        :disabled="isTabDisabled(tab)"
        :title="tabName[tab]"
        :key="tab"
        :active="currentTab === tab"
        @click="handleTabClick(tab)"
      )
        .header {{ tabName[tab] }}
        BasicSettingsForm(
          v-if="tab === 'basic_settings'"
          ref="basic_settings"
          :shop-id="shopId"
          :active="currentTab === 'basic_settings'"
          @remove-shop="removeShop"
        )
        TimeSettingsForm(
          v-if="tab === 'time_settings' && !isTabDisabled(tab)"
          ref="time_settings"
          :shop-id="existingShopId"
          :active="currentTab === 'time_settings'"
          @remove-shop="removeShop"
          @refresh-shop="refreshShop"
        )
        AccessSettingsForm(
          v-if="tab === 'access_settings' && !isTabDisabled(tab)"
          ref="access_settings"
          :shop-id="existingShopId"
          :active="currentTab === 'access_settings'"
          @remove-shop="removeShop"
          @refresh-shop="refreshShop"
        )
</template>

<script>
  // misc
  import { get, reduce } from "lodash-es"

  // components
  import { BTabs, BTab } from "bootstrap-vue"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  // stores
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"
  import accessSettingModule from "@/config/store/company_system/shops_settings/shops/access_settings"
  import timeSettingModule from "@/config/store/company_system/shops_settings/shops/time_settings"

  const companiesShopsMixin = withStoreModule(shopsModule, {
    name: "companiesShops",
    readers: {
      shop: "item",
      shopLoading: "loading"
    },
    actions: {
      fetchShop: "FETCH_ITEM",
      deleteShop: "DELETE_ITEM"
    }
  })

  const companiesShopAccessSettingMixin = withStoreModule(accessSettingModule, {
    name: "companiesShopAccessSetting",
    readers: { accessSettingLoading: "loading" }
  })

  const companiesShopTimeSettingMixin = withStoreModule(timeSettingModule, {
    name: "companiesShopTimeSettingMixin",
    readers: { timeSettingLoading: "loading" }
  })

  export default {
    components: {
      BTabs,
      BTab,
      AppIcon: () => import("@/components/elements/AppIcon"),
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      BasicSettingsForm: () => import("./BasicSettingsForm"),
      TimeSettingsForm: () => import("./TimeSettingsForm"),
      AccessSettingsForm: () => import("./AccessSettingsForm"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [companiesShopsMixin, companiesShopAccessSettingMixin, companiesShopTimeSettingMixin, withConfirmation],

    props: {
      shopId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        tabs: ["basic_settings", "time_settings", "access_settings"],
        currentTab: "basic_settings"
      }
    },

    computed: {
      existingShopId() {
        return this.shopId || this.shop.id
      },

      loading() {
        return this.shopLoading || this.accessSettingLoading || this.timeSettingLoading
      },

      tabName() {
        return reduce(
          this.tabs,
          (obj, tab) => {
            obj[tab] = this.$t(`company_system.shop_settings.shop_form.${tab}.title`)
            return obj
          },
          {}
        )
      },

      hasUnsavedChanges() {
        return get(this.$refs, `[${this.currentTab}][0].hasUnsavedChanges`, false)
      }
    },

    methods: {
      isTabDisabled(tab) {
        return tab !== "basic_settings" && !this.existingShopId
      },

      handleTabChange(_newTab, _oldTab, event) {
        if (this.hasUnsavedChanges) event.preventDefault()
      },

      handleTabClick(tab) {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: () => {
            this.currentTab = tab
          }
        })
      },

      handleClickBack() {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: () => this.$emit("back")
        })
      },

      refreshShop() {
        this.fetchShop(this.existingShopId)
      },

      removeShop() {
        this.$confirm({
          title: this.$t("company_system.shop_settings.delete_shop_confirmation"),
          resolve: {
            handler: () => {
              this.deleteShop(this.shopId)
              this.$emit("back")
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .shop-form-container
    position: relative
    .row
      &.back
        margin-left: 10px

    ::v-deep
      .card-header
        background-color: transparent
        padding: 0.75rem 0
      .nav-link
        border-color: transparent
        &.active
          background-color: transparent
          color: $default-black
          font-weight: 600

        &:hover
          border-color: transparent
      .nav-wrapper
        width: 160px

  .header
    border-left: 13px $default-purple-light solid
    padding: 6px
    font-weight: 700
    font-size: 16px
    line-height: 24px
    margin-bottom: 24px
</style>
